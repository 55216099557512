@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Montserrat', sans-serif;
}

ul {
  list-style: disc inside;
}

@layer components {
  body {
    @apply bg-black text-blue-50 font-thin;
  }

  h1 {
    @apply font-bold text-4xl uppercase;
  }

  h2, h3, h4, h5, h6 {
    @apply font-normal;
  }

  p, ul, ol {
    @apply my-6;
  }

  a:hover {
    @apply text-blue-300;
  }
}